import { gql } from 'apollo-boost'

export const GET_INVITE = gql`
  query invite ($token: ID!) {
    invite(token: $token) {
      inviter {
        avatar
        name
        tagLine
      }
      status
    }
  }
`

export const FACEBOOK_AUTH = gql`
  mutation auth ($token: String!) {
    auth(fbToken: $token) {
      token
    }
  }
`

export const APPLE_AUTH = gql`
  mutation appleAuth ($token: String!, $email: String!, $name: String!, $id: String!) {
    authApple(token: $token, email: $email, name: $name, id: $id) {
      token
    }
  }
`

export const CONFIRM_INVITE = gql`
  mutation confirmInvite ($token: ID!) {
    confirmInvite(token: $token)
  }
`
