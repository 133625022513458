import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import get from 'lodash/get'

const CHECK_AUTH = gql`
  query checkAuth {
    checkAuth {
      logged
    }
  }
`

const useAuth = () => {
  const { data } = useQuery(CHECK_AUTH)

  return get(data, 'checkAuth.logged', false)
}

export default useAuth

