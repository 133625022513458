import React from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login'
import { useMutation } from '@apollo/react-hooks'
import { FACEBOOK_AUTH } from '../gql/invite'
import get from 'lodash/get'
import Cookies from 'js-cookie'

const Auth = ({ onComplete }) => {
  const [facebookAuth] = useMutation(FACEBOOK_AUTH, {
    onCompleted: async (data) => {
      const token = get(data, 'auth.token', null)
      if (token) {
        console.log(token)
        Cookies.set('authToken', token)
        onComplete(token)
      }
    }
  })

  return (
    <FacebookLogin
      appId="1628081653970398"
      autoLoad={true}
      fields="name,email,picture"
      callback={({ accessToken }) => facebookAuth({ variables: { token: accessToken }})}
      cssClass="facebook-button"
    />
  )
}

Auth.propTypes = {
  onComplete: PropTypes.func,
}

Auth.defaultProps = {
  onComplete: () => null
}

export default Auth
